<template>
  <a-breadcrumb :routes="routes" separator=">" class="breadcrumb">
    <template #itemRender="{ route, routes }">
      <span v-if="routes.indexOf(route) === routes.length - 1">
        {{ route.breadcrumbName }}
      </span>
      <router-link v-else :to="`${routes.find(v => v === route)['path']}`">
        {{ route.breadcrumbName }}
      </router-link>
    </template>
  </a-breadcrumb>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array
    }
  },
  data() {
    return {
      basePath: ''
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
  padding: 12px;
}

.ant-breadcrumb {
  span {
    font-size: 18 / @vw;
    color: #c6c6c6;

    &:last-child {
      color: #242424;
    }
  }

  /deep/ span.ant-breadcrumb-separator {
    margin: 0;
  }

  a {
    font-size: 18 / @vw;
    color: #c6c6c6;
  }
}
</style>
