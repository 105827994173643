var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-button',{class:{ disabled: _vm.disabled },style:({
    color: _vm.color,
    backgroundColor: _vm.backgroundColor,
    width: _vm.width,
    height: _vm.height,
    border: _vm.border,
    borderRadius: _vm.borderRadius,
    fontSize: _vm.fontSize
  }),attrs:{"size":_vm.size,"disabled":_vm.disabled,"type":_vm.type,"icon":_vm.icon,"loading":_vm.loading,"shape":_vm.shape,"block":_vm.block},on:{"click":_vm.onClickBtn}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }