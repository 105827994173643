<!--button组件-->
<!--
  <FsButton>
    默认白色背景黑色字体
    </FsButton>
  <FsButton type="primary">
    绿色背景白色字体
    </FsButton> 
  <FsButton>
    <a-icon type="user" />加icon新建
  </FsButton> 
  <FsButton style="width:100px"> 自定义样式
    <a-icon type="user" />加icon新建
  </FsButton> 
-->
<template>
  <a-button
    @click="onClickBtn"
    :size="size"
    :disabled="disabled"
    :type="type"
    :icon="icon"
    :loading="loading"
    :shape="shape"
    :block="block"
    :class="{ disabled: disabled }"
    :style="{
      color: color,
      backgroundColor: backgroundColor,
      width: width,
      height: height,
      border: border,
      borderRadius: borderRadius,
      fontSize: fontSize
    }"
  >
    <slot></slot>
  </a-button>
</template>
<script>
// type="primary" 绿色背景白色字体
// type="normal" 绿色边框绿色字体
export default {
  props: {
    disabled: {
      type: Boolean
    },
    size: {
      type: String
    },
    type: {
      type: String
    },
    block: {
      type: Boolean
    },
    shape: {
      type: String
    },
    icon: {
      type: String
    },
    loading: {
      type: Boolean
    },
    color: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    borderRadius: {
      type: String
    },
    border: {
      type: String
    },
    fontSize: {
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    onClickBtn() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="less" scoped>
.ant-btn {
  border-color: #c6c6c6;
  width: 90 / @vw;
  height: 42 / @vw;
  font-size: 18 / @vw;
  padding: 0 15 / @vw;
  color: #7c7c7c;
  &.disabled {
    background: #e2e5e4;
    border-color: #e2e5e4;
    color: #a1a9ac;
  }
  &.ant-btn-primary {
    background: #2b9363;
    border-color: #2b9363;
    color: #fff;
  }
  &.ant-btn-normal {
    border-color: #2b9363;
    color: #2b9363;
  }
  &.ant-btn-danger {
    background: #ff5740;
    border-color: #ff5740;
    color: #fff;
  }
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  box-shadow: 0 0 0 2px rgba(106, 195, 88, 0.2);
}
</style>
